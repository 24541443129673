import { LoginParams } from '@providers/hooks/LoginHook';
import { ObjectType } from '@service/model';
import { NextRouter, useRouter } from 'next/router';

interface OnLoginStayHere {
  stayHere: boolean;
  redirectTo?: never;
}

interface OnLoginRedirectTo {
  stayHere?: never;
  redirectTo: string;
}

type OnLogin = OnLoginStayHere | OnLoginRedirectTo;

interface AcoRouter {
  login: (onLogin?: OnLogin) => void;
  object: (type: ObjectType, id: string | number) => void;
  register: () => void;
  home: () => void;
  router: NextRouter;
  profile: (id: string | number) => void;
}

enum AcoRoutes {
  LOGIN = '/login',
  HOME = '/'
}

const safe = (router: NextRouter) => {
  return {
    push: (url: URL | string): void => {
      router.push(url)
        .catch(err => {
          console.error(err);

          return false;
        });
    },
  };
};

const loginFor = (router: NextRouter): (opts?: OnLogin) => void => {
  const actualUrl = (typeof window !== undefined) ? '' : window.location.href.replace(`${window.location.protocol}//`, '').replace(window.location.host, '');

  return (opts?: OnLogin) => {
    const params = !opts || !Object.keys(opts).length ? '' : `?${LoginParams.DESTINATION}=${opts.stayHere ? encodeURIComponent(actualUrl) : encodeURIComponent(opts.redirectTo)}`;
    const loginUrl = `${AcoRoutes.LOGIN}${params}`;
    safe(router).push(loginUrl);
  };
};


export const useAcoRouter = (): AcoRouter => {
  const router = useRouter();

  const safeRouter = safe(router);

  const login = loginFor(router);

  const home = () => safeRouter.push(AcoRoutes.HOME);

  const object = (type: ObjectType, id: string | number) => safeRouter.push(`/${type}/${id}`);

  const profile = (id: string | number) => safeRouter.push(`/user/${id}`);

  const register = () => safeRouter.push('/register')

  return { router, login, home, object, profile, register };
};