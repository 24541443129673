import { Button } from '@mui/material';
import { withStyles } from 'tss-react/mui';


const AcoButton = withStyles(Button, (theme ) => ({
  root: {
    borderRadius: '6px',
    textTransform: 'none',
    padding: '12px 16px',
    fontSize: theme.typography.subtitle2.fontSize,
    '&.MuiButton-sizeLarge': {
      padding: '14px 18px',
      fontSize: theme.typography.subtitle1.fontSize
    },
    '&.MuiButton-sizeSmall': {
      padding: '8px 12px',
      fontSize: theme.typography.body2.fontSize
    }
  },
  defaultProps: {
    disableElevation: true
  }
}));

export default AcoButton;