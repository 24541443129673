import { AppBar, AppBarProps, Box } from '@mui/material';
import React, { useState } from 'react';

interface StickyAppBarProps extends AppBarProps {
  margin?: number;
  timeout?: number;
}

export const StickyAppBar: React.FC<StickyAppBarProps> = ({ timeout, ...props }) => {
  const barRef = React.createRef<HTMLDivElement>();
  const [barHeight, setBarHeight] = useState(50);

  const updateHeight = () => {
    const currentHeight = barRef?.current?.clientHeight || 0;

    const newHeight = Math.max(currentHeight, barHeight);
    setBarHeight(newHeight);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateHeightTrigger = () => timeout && timeout > 0 ? setTimeout(() => updateHeight(), timeout + timeout * .05) : updateHeight();

  React.useEffect(() => {
    updateHeightTrigger();
  }, [barRef, barHeight, updateHeightTrigger]);

  return (
    <>
      <Box height={barHeight} minHeight={barHeight} />
      <AppBar color='primary' enableColorOnDark ref={barRef} {...props} />
    </>
  );
};

StickyAppBar.defaultProps = {
  margin: 0,
  timeout: 0,
};