import { Hidden, HiddenProps, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Theme as DefaultTheme } from '@mui/material/styles/createTheme';
import { makeStyles } from '@mui/styles';
import { ClassNameMap, Styles, WithStylesOptions } from '@mui/styles/withStyles';
import theme from '@theme';
import React from 'react';

/**
 * value         |0px     600px    960px    1280px   1920px
 * key           |xs      sm       md       lg       xl
 * screen width  |--------|--------|--------|--------|-------->
 * range         |   xs   |   sm   |   md   |   lg   |   xl
 *
 * Note: down includes
 */

function onMobile(actualTheme = theme) {
  return actualTheme.breakpoints.down('md');
}

function onDesktop(actualTheme = theme) {
  return actualTheme.breakpoints.up('md');
}

const HiddenWrapper: React.FC<{ device: 'mobile' | 'desktop' }> = ({ children, device }) => {
  const hideProp = device === 'mobile' ? 'mdUp' : 'mdDown';
  const props: HiddenProps = { [hideProp]: true };

  // @ts-ignore
  return <Hidden {...props}>{children}</Hidden>;
};

export const OnMobile: React.FC = (props) => <HiddenWrapper {...props} device='mobile' />;
export const OnDesktop: React.FC = (props) => <HiddenWrapper {...props} device='desktop' />;
export const HideOnDesktop = OnMobile;
export const HideOnMobile = OnDesktop;

export class StyleResponsiveProvider {
  constructor(private theme?: Theme) {}

  /**
   * class name for child sass to use when viewport is lower than <md> (960px)<br/>
   * `Screen size <= 960px`
   */
  public get onMobile() {
    return onMobile(this.theme);
  }

  /**
   * class name for child sass to use when viewport is upper than <md> (960px)<br/>
   * `Screen size >= 960px`
   */
  public get onDesktop() {
    return onDesktop(this.theme);
  }

  public static on(theme: Theme) {
    return new StyleResponsiveProvider(theme);
  }
}

/**
 * @deprecated Use <code>makeResponsiveStyles(responsive => theme => ({ ... }))</code>
 */
export const StyleResponsive = new StyleResponsiveProvider();

const useThemedMediaQuery = (breakpointResolver: (theme: Theme) => string) => {
  const theme = useTheme();

  return useMediaQuery(breakpointResolver(theme));
};

export const useIsMobile = () => useThemedMediaQuery(onMobile);

export const useIsDesktop = () => useThemedMediaQuery(onDesktop);

export enum ResponsiveDevice {
  DESKTOP,
  MOBILE,
}

export const useResponsive = () => (useIsMobile() ? ResponsiveDevice.MOBILE : ResponsiveDevice.DESKTOP);

/**
 * Overload of {@link withStyles} using {@link StyleResponsiveProvider}. Example to use:
 * <br/>
 * <pre>
 *    const useExampleStyles = makeResponsiveStyles(responsive => theme => ({
 *      className: {
 *        [responsive.onMobile]: {
 *          color: theme.palette.primary.main
 *        }
 *      }
 *    }))
 *
 * </pre>
 */
export function makeResponsiveStyles<
  Theme = DefaultTheme,
  // eslint-disable-next-line @typescript-eslint/ban-types
  Props extends object = {},
  ClassKey extends string = string
>(
  styles: (responsive: StyleResponsiveProvider) => Styles<Theme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, 'withTheme'>
): keyof Props extends never // `withStyles` where the passed `styles` do not depend on props
  ? // eslint-disable-next-line
    (props?: any) => ClassNameMap<ClassKey> // `withStyles` where the passed `styles` do depend on props
  : (props: Props) => ClassNameMap<ClassKey> {

  // @ts-ignore
  return (props?: Props) => {
    const theme = useTheme();
    const responsive = StyleResponsiveProvider.on(theme);
    const useStyles = makeStyles(styles(responsive), options);

    return useStyles(props);
  };
}
