import { CreateCSSProperties } from '@mui/styles/withStyles/withStyles';
import { makeResponsiveStyles } from '@providers/Responsive.provider';

export interface AvatarSize {
  desktop: number;
  mobile: number;
}

export interface AvatarStyleProps {
  size: AvatarSize;
}

const LEGACY_ZOOM = 1.35;

export const useAvatarStyles = makeResponsiveStyles(responsive => ({
  avatar: {
    stroke: '#bdbdbd',
    height: props => props.size.desktop,
    width: props => props.size.desktop,
    [responsive.onMobile]: {
      width: props => props.size.mobile,
      height: props => props.size.mobile,
    },
    '&.legacy > img': {
      height: props => props.size.desktop * LEGACY_ZOOM,
      [responsive.onMobile]: {
        height: props => props.size.mobile * LEGACY_ZOOM,
      }
    }
  } as CreateCSSProperties<AvatarStyleProps>
}), {index: 1});
