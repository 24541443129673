import { useImageLegacy } from '@components/Avatar/Avatar.img.legacy.hook';
import { AvatarSize, useAvatarStyles } from '@components/Avatar/Avatar.style';
import { Avatar as UIAvatar } from '@mui/material';
import clsx, { ClassValue } from 'clsx';
import React from 'react';

const nonWordAndSpaceRegExp = /[^a-zA-Z ]/g;
const wordBoundaryRegExp = /\b\w/g;

const getInitials = (name: string) => {
  if (name == undefined) return '-';

  const  initials = name.replace(nonWordAndSpaceRegExp, '').match(wordBoundaryRegExp);

  return [initials[0], initials[initials.length -1]].join('').toUpperCase();
}

const stringToHslColor = (str: string) => {
  if (str == undefined) return '#bdbdbd';
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;

  return 'hsl('+h+', 30%, 80%)';
}

export type AvatarProps = ImageAvatar | InitialsNameAvatar;

interface BaseAvatar {
  classes?: ClassValue
  srcThumbnail?: string;
  alt?: string;
  url?: string;
  onClick?: () => void;
  size?: number | AvatarSize
}

interface ImageAvatar extends BaseAvatar {
  src: string;
}

interface InitialsNameAvatar extends BaseAvatar {
  name: string;
}

const ImageAvatar = (props: ImageAvatar) => {
  const classes = useAvatarStyles({ size: typeof props.size === 'number' ? { desktop: props.size, mobile: props.size } : props.size });
  const isLegacy = useImageLegacy(props.src);

  return <UIAvatar
    src={props.src}
    alt={props.alt}
    className={clsx(classes.avatar, { legacy: isLegacy }, props.classes)}
    variant='rounded'
    onClick={props.onClick || (props.url && doRedirect(props.url))}
  />;
};

const InitialsNameAvatar = (props: InitialsNameAvatar) => {
  const classes = useAvatarStyles({ size: typeof props.size === 'number' ? { desktop: props.size, mobile: props.size } : props.size });

  return <UIAvatar
    alt={props.alt}
    className={clsx(classes.avatar, props.classes)}
    style={{backgroundColor: stringToHslColor(props.name || props.alt)}}
    variant='rounded'
    onClick={props.onClick || (props.url && doRedirect(props.url))}
  >{getInitials(props.name || props.alt)}</UIAvatar>;
};

export const Avatar: React.VFC<AvatarProps> = (props) => {
  const isImageAvatar = !!(props as ImageAvatar).src;

  return <>{isImageAvatar? <ImageAvatar {...props as ImageAvatar}/> : <InitialsNameAvatar {...props as InitialsNameAvatar} /> }</>
};

Avatar.defaultProps = {
  size: { desktop: 50, mobile: 38 }
};

function doRedirect(url: string) {
  return () => window.location.href = url;
}
