import { useSession } from '@providers/user/SessionContext';
import { LoginProps, LoginResult } from '@service/login.service';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useState } from 'react';

export enum LoginParams {
  DESTINATION = 'destination'
}


export enum LoginStatus {
  LOADING,
  ERROR,
  OK,
  LOGGED
}

export const useLogin = () => {
  const [stateLogin, setStateLogin] = useState(LoginStatus.OK);
  const [loginError, setLoginError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const router = useRouter();
  const session = useSession();

  function login(props: LoginProps) {

    setStateLogin(LoginStatus.LOADING);

    axios.post<LoginResult>('/api/login', props)
      .then((res) => {
        const urlParams = new URLSearchParams(window.location.search);
        const urlRedirection = urlParams.get(LoginParams.DESTINATION) ? urlParams.get(LoginParams.DESTINATION) : '/';

        if (res.data.success && !res.data.error) {
          session.refreshToken().then(
            () => {
              setStateLogin(LoginStatus.LOGGED);

              {
                !props.notRedirect && router.push(urlRedirection);
              }
            },
          ).catch((e) => {
            console.error(e);
            setStateLogin(LoginStatus.ERROR);
          });

        } else {
          setStateLogin(LoginStatus.ERROR);

          if (res.data.error) {
            setEmail(res.data.data);
            setLoginError(res.data.error);
          }
        }
      });
  }


  /*TODO: Adaptar login para que redireccione o no, dependiendo de lo que se pida*/

  const loginNoRedirect = (user, password) => {
    const data = { user, password };
    setStateLogin(LoginStatus.LOADING);

    axios.post<LoginResult>('/api/login', data)
      .then((res) => {

        if (res.data.success) {
          session.refreshToken().then(
            () => {
              setStateLogin(LoginStatus.LOGGED);
            });
        } else {
          setStateLogin(LoginStatus.ERROR);
        }
      });
  };


  return { stateLogin, loginError, email, login, loginNoRedirect };
};
