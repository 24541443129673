import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {StyleResponsive} from '@providers/Responsive.provider';

const LOGO_SIZE = {
  large: { w: 160, h: 28 },
  small: { w: 28, h: 30 }
}

export default makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      padding: '0 10px',
      minHeight: '56px',
      [StyleResponsive.onMobile]: {
        width: '100vw',
        left: 0,
      }
    },
    mainGrid: {
      [StyleResponsive.onDesktop]: {
        margin: '0 5px',
      },
      [StyleResponsive.onMobile]: {
        padding: '7px 0',
      },
      width: '100%',
    },

    searchGroup: {
      maxWidth: 513,
      width: '100%'
    },

    secondaryGrid: {
      marginTop: 4,
      width: '100%'
    },

    logo: {
      width: `${LOGO_SIZE.small.w}px`,
      height: LOGO_SIZE.small.h,
      maxHeight: '29px',
      fill: 'white'
    },

    logoFull: {
      width: `${LOGO_SIZE.large.w}px`,
      fill: 'white',
      maxHeight: '29px'
    },

    logoImgContainer: {
      '& a': {
        display: 'flex'
      }
    },

    logoSmallIn: {
      animation: `$logoSlideSmall 300ms ${theme.transitions.easing.easeInOut}`
    },

    logoLargeIn: {
      animation: `$logoSlideLarge 100ms ${theme.transitions.easing.easeInOut}`,
      position: 'relative',
      display: 'table',
      height: LOGO_SIZE.large.h,
      width: 240,
    },

    '@keyframes logoSlideSmall': {
      '0%': {
        width: LOGO_SIZE.large.w
      },
      '100%': {
        width: LOGO_SIZE.small.w,
      }
    },
    '@keyframes logoSlideLarge': {
      '0%': {
        width: LOGO_SIZE.small.w
      },
      '100%': {
        width: LOGO_SIZE.large.w
      }
    },

    text: {
      letterSpacing: '0.1px',
      textTransform: 'none',
    },

    mobileLogoContainer: {
      display: 'flex'
    },

    buttonConfig: {
      height: '40px',
      border: '2px solid #FFFFFF',
      color: '#FFFFFF',
      boxSizing: 'border-box',
      minWidth: '40px',
      width: '40px',
    },


  })
);
